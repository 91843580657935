<template>
    <div class="not-found">
        <h2>404</h2>
        <p>Nie znaleziono strony</p>
    </div>
</template>

<script>
export default {
    name: "PageNotFound"
}
</script>

<style scoped lang="scss">
.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 28px);

    & h2 {
        font-size: 5rem;
    }

    p {
        font-size: 3rem;
    }
}

</style>