<template>
  <div class="home">
      <div class="exhibition-types-container">
          <ul>
              <li v-for="exhibitionType in exhibitionTypes">
                  <router-link :to="{name: 'Exhibition', params: {id: exhibitionType.id}}">
                      <div>
                        {{ exhibitionType.name }}
                      </div>
                      <img src="@/assets/icons/arrow-right-short.svg" />
                  </router-link>
              </li>
          </ul>
      </div>
  </div>
</template>

<script>
import exhibitionTypes from '@/store/exhibitonTypes'
export default {
    name: 'Home',
    setup() {
        return {
            exhibitionTypes
        }
    }
}
</script>

<style scoped lang="scss">
.home {
    padding: 20px;
    max-width: 600px;
    margin: auto;

    ul {
        max-width: 400px;
        margin: auto;
        li {
            border: #fff 1px solid;
            margin-bottom: 20px;

            &:hover {
                background-color: #455B77;
            }

            a {
                color: #fff;
                padding: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                img {
                    height: 1.6rem;
                }
            }
        }
    }
}
</style>