const exhibitionTypes = [
    {
        id: 1,
        name: 'Czartoryskiego'
    },
    {
        id: 2,
        name: 'Dworcowa 26'
    },
    {
        id: 3,
        name: 'Fontanna Potop'
    },
    {
        id: 4,
        name: 'Gdańska róg Placu Wolności'
    },
    {
        id: 5,
        name: 'Kanał Zbożowy'
    },
    {
        id: 6,
        name: 'Marcinkowskiego'
    },
    {
        id: 7,
        name: 'Mosty Solidarności'
    },
    {
        id: 8,
        name: 'Poczta Jagiellońska'
    },
    {
        id: 9,
        name: 'Remiza Pomorska'
    },
    {
        id: 10,
        name: 'Rybi Rynek'
    },
    {
        id: 11,
        name: 'Stary Port-Mostowa'
    },
    {
        id: 12,
        name: 'Stary Rynek 9-13'
    },
    {
        id: 13,
        name: 'Stary Rynek pierzeje'
    },
    {
        id: 14,
        name: 'Śluza Miejska'
    },
]

export default exhibitionTypes